export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}

export enum PageName {
    OneTimePayments = "OneTimePayments",
    PaymentTemplates = "PaymentTemplates",
}

export enum PageNameId {
    SelectPayments = 1,
    ApprovePayments = 2,
    ViewHistory = 3,
    ExportPayments = 4,
    SyncPayments = 5,
    PaymentDashboardBankAccounts = 6,
    OneTimePayments = 7,
    PaymentTemplates = 8,
    ScheduledPayments = 9,
    ApAging = 10,
    PaymentDashboardNotifications = 11,
    OneTimePaymentsAccountEntries = 12,
    PaymentTemplatesPaymentFilters = 13,
}

export enum GridActionType {
    FilterChange = 'filter-change',
    SortChange = 'sort-change',
    GroupChange = 'group-change',
    ResetToDefaultView = 'reset-to-default-view',
    GroupCollapse = 'group-collapse',
    GroupExpand = 'group-expand',
}