import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import './polyfills.ts';
import { RootModule } from './root.module';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { Replay } from '@sentry/replay';

if (environment.production) {
    Sentry.init({
        dsn: "https://bf1c3b2918544d46b2c64ad9ae382db6@o503696.ingest.us.sentry.io/5589139",
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: [
                    'localhost',
                    /^ https: \/\/yourserver\.io\/api/
                ],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new Replay() // Enable Replay integration
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    });

    enableProdMode();
}
else if (environment.stage) {
    Sentry.init({
        dsn: "https://bf1c3b2918544d46b2c64ad9ae382db6@o503696.ingest.us.sentry.io/5589139",
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: [
                    'localhost',
                    /^ https: \/\/yourserver\.io\/api/
                ],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new Replay() // Enable Replay integration
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    });
}
else {
    Sentry.init({
        dsn: "https://acedaed803eb448193d25cf2d4cfa423@o4505386001825792.ingest.sentry.io/4505386003267584",
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: [
                    'localhost',
                    /^ https: \/\/yourserver\.io\/api/
                ],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new Replay() // Enable Replay integration
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    });
}

const bootstrap = () => {
    return platformBrowserDynamic().bootstrapModule(RootModule);
};

/* "Hot Module Replacement" is enabled as described on
 * https://medium.com/@beeman/tutorial-enable-hrm-in-angular-cli-apps-1b0d13b80130#.sa87zkloh
 */

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap); //HMR enabled bootstrap
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap(); //Regular bootstrap
}
