import { Component, Injector } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Component({
    template: ``,
    animations: [appModuleAnimation()]
})
export class LogoutComponent extends AppComponentBase {
    constructor(
        injector: Injector,
        private _authService: AppAuthService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.showMainSpinner();
        this._authService.logout();
    }
}
