import { Component, Injector, OnInit, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { ThemeAssetContributorFactory } from '@shared/helpers/ThemeAssetContributorFactory';
import { StreamlineaSettingsServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
    templateUrl: './footer.component.html',
    selector: 'footer-bar'
})
export class FooterComponent extends AppComponentBase implements OnInit {
    version: string = "";
    releaseDate: string = "";
    @Input() useBottomDiv = true;
    webAppGuiVersion: string;

    footerStyle = 'footer bg-white py-4 d-flex flex-lg-column';

    constructor(
        injector: Injector,
        private _streamlineaSettingsServiceProxy: StreamlineaSettingsServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this._streamlineaSettingsServiceProxy.getVersionNumber().subscribe(result => {
            this.version = result.versionNumber;
            this.releaseDate = result.releaseDate;

            if (this.version === "") {
                this.version = this.appSession.application.version;
            }

            if (this.releaseDate === "") {
                this.releaseDate = this.appSession.application.releaseDate.toFormat('yyyyLLdd');
            }
        });

        this.webAppGuiVersion = AppConsts.WebAppGuiVersion;

        let themeAssetContributor = ThemeAssetContributorFactory.getCurrent();
        if (themeAssetContributor) {
            this.footerStyle = themeAssetContributor.getFooterStyle();
        }
    }
}
