import { Component, Injector } from '@angular/core';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { AppComponentBase } from './shared/common/app-component-base';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="" type="cube-transition" [fullScreen]="false" template='<div class="freeze-ui" data-text="{{getSpinnerText()}}"> </div> <style> @keyframes spin { 0% { transform: translateZ(0) rotate(0) } 100% { transform: translateZ(0) rotate(360deg) } } .freeze-ui { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 999999999; background-color: #fff; opacity: .8; transition: opacity .25s } .freeze-ui.is-unfreezing { opacity: 0 } .freeze-ui:before { content: ""; display: block; width: 150px; height: 150px; border-radius: 50%; border-width: 2px; border-style: solid; border-color: transparent #228AE6 #228AE6; position: absolute; top: calc(50% - 75px); left: calc(50% - 75px); will-change: transform; animation: spin .75s infinite ease-in-out; } .freeze-ui:after { content: attr(data-text); display: block; max-width: 125px; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); font-size: 20px; font-family: sans-serif; color: #343a40; text-align: center; text-transform: uppercase; } </style>'></ngx-spinner>
    `
})
export class RootComponent extends AppComponentBase {
    //ngxSpinnerText: NgxSpinnerTextService;

    constructor(
        injector: Injector
    ) {
        super(injector);
        //this.ngxSpinnerText = _ngxSpinnerText;
    }

    ngOnInit() {
        this.showMainSpinner();
        //this.ngxSpinnerTextService.setText("LOADING...");
    }
}
