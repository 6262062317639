import { Directive, Input, ComponentFactoryResolver, ViewContainerRef, Injector, SimpleChanges, OnChanges } from '@angular/core';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';

@Directive({
    selector: '[busyIf]'
})
export class BusyIfDirective implements OnChanges {

    constructor(
        private _viewContainer: ViewContainerRef,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _injector: Injector
    ) {
        this.ngxSpinnerService = _injector.get(NgxSpinnerService);
        this.loadComponent();
    }

    private static index = 0;
    @Input() busyIf: boolean;

    ngxSpinnerService: NgxSpinnerService;
    private spinnerName = '';

    isBusy = false;
    refreshState(): void {
        if (this.isBusy === undefined || this.spinnerName === '') {
            return;
        }

        setTimeout(() => {
            if (this.isBusy) {
                this.ngxSpinnerService.show(this.spinnerName);
            } else {
                this.ngxSpinnerService.hide(this.spinnerName);
            }
        }, 1000);
    }

    loadComponent() {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(NgxSpinnerComponent);
        const componentRef = this._viewContainer.createComponent(componentFactory);
        this.spinnerName = 'busyIfSpinner-' + (BusyIfDirective.index++) + '-' + Math.floor(Math.random() * 1000000); // generate random name
        let component = (<NgxSpinnerComponent>componentRef.instance);
        component.name = this.spinnerName;
        component.fullScreen = false; //true - fixed | false - absolute
        component.bdColor = "rgba(255,255,255,0.8)";
        component.template = `
                            <div class="freeze-ui" data-text="LOADING..."></div>
                            <style>
                                @keyframes spin {
                                    0% {
                                        transform: translateZ(0) rotate(0)
                                    }

                                    100% {
                                        transform: translateZ(0) rotate(360deg)
                                    }
                                }

                                .freeze-ui {
                                    position: fixed;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 999999999;
                                    background-color: #fff;
                                    opacity: .8;
                                    transition: opacity .25s
                                }

                                .freeze-ui.is-unfreezing {
                                    opacity: 0
                                }

                                .freeze-ui:before {
                                    content: "";
                                    display: block;
                                    width: 150px;
                                    height: 150px;
                                    border-radius: 50%;
                                    border-width: 2px;
                                    border-style: solid;
                                    border-color: transparent #228AE6 #228AE6;
                                    position: absolute;
                                    top: calc(50% - 75px);
                                    left: calc(50% - 75px);
                                    will-change: transform;
                                    animation: spin .75s infinite ease-in-out;
                                }

                                .freeze-ui:after {
                                    content: attr(data-text);
                                    display: block;
                                    max-width: 125px;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%,-50%);
                                    font-size: 20px;
                                    font-family: sans-serif;
                                    color: #343a40;
                                    text-align: center;
                                    text-transform: uppercase;
                                }
                            </style>
                            `;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.busyIf) {
            this.isBusy = changes.busyIf.currentValue;
            this.refreshState();
        }
    }
}
