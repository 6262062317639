import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('PaymentDashboard', 'Pages.PaymentDashboard', 'flaticon-line-graph', '/app/main/payment-dashboard'),
            new AppMenuItem('APAging', 'Pages.APAging', 'flaticon-clock-2', '/app/main/ap-aging'),
            new AppMenuItem('SelectPayments', 'Pages.SelectPayments', 'flaticon-add', '/app/main/select-payments/full', [], [
                new AppMenuItem('NormalView', 'Pages.SelectPayments', 'flaticon-add', '/app/main/select-payments/full'),
                new AppMenuItem('ScrollView', 'Pages.SelectPayments', 'flaticon-add', '/app/main/select-payments/detail')
            ]),
            new AppMenuItem('ApprovePayments', 'Pages.ApprovePayments', 'flaticon-list', '/app/main/approve-payments/full', [], [
                new AppMenuItem('NormalView', 'Pages.ApprovePayments', 'flaticon-list', '/app/main/approve-payments/full'),
                new AppMenuItem('ScrollView', 'Pages.ApprovePayments', 'flaticon-list', '/app/main/approve-payments/detail')
            ]),
            new AppMenuItem('SyncPayments', 'Pages.SyncPayments', 'flaticon-open-box', '/app/main/sync-payments'),
            new AppMenuItem('ExportPayments', 'Pages.ExportPayments', 'flaticon-open-box', '/app/main/export-payments'),
            new AppMenuItem('ViewHistory', 'Pages.ViewHistory', 'flaticon-interface-3', '/app/main/view-history'),
            new AppMenuItem('OneTimePayments', 'Pages.CreateOneTimePayment', 'flaticon-plus', '/app/main/one-time-payments'),
            new AppMenuItem('PaymentTemplates', 'Pages.PaymentTemplates', 'flaticon-interface-11', '/app/main/payment-templates'),
            new AppMenuItem('PaymentTemplatesCalendar', 'Pages.CalendarPaymentTemplates', 'flaticon-calendar-2', '/app/main/payment-templates-calendar'),
            new AppMenuItem('ScheduledPayments', 'Pages.ScheduledPayments', 'flaticon-time-3', '/app/main/scheduled-payments'),
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('DynamicRoles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/dynamicRoles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                //new AppMenuItem('ERPIntegrationSettings', 'Pages.Administration.Tenant.Settings', 'flaticon2-hourglass-1', '/app/admin/erp-integration-settings'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ]),
            new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
