<div class="row row-no-padding row-col-separator-xl h-100" [perfectScrollbar]>
    <div class="col pr-0">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a href="javascript:;" class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark d-block">
                    Total Profit
                </a>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4">All Customs Value</span>
                <div class="text-left text-primary font-weight-bolder font-size-h2 mr-2">
                    $<span counto [step]="30" [duration]="1"
                           [countFrom]="0" [countTo]="dashboardTopStats.totalProfit"
                           (countoChange)="dashboardTopStats.totalProfitCounter = $event">
                                {{dashboardTopStats.totalProfitCounter.toFixed(0)}}
                            </span>
                </div>
                <div class="progress progress-xs bg-primary-o-60">
                    <div class="progress-bar bg-primary" role="progressbar" style="width: 76%;" aria-valuenow="76"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.totalProfitChange}}</span>%
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="col pr-0">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a href="javascript:;"
                   class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark d-block">
                    New Feedbacks
                </a>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4">Customer Review</span>
                <div class="text-left text-info font-weight-bolder font-size-h2 mr-2">
                    $<span counto [step]="30" [duration]="1" [countFrom]="0"
                           [countTo]="dashboardTopStats.newFeedbacks"
                           (countoChange)="dashboardTopStats.newFeedbacksCounter = $event">
                            {{dashboardTopStats.newFeedbacksCounter.toFixed(0)}}
                            </span>
                </div>
                <div class="progress progress-xs bg-info-o-60">
                    <div class="progress-bar bg-info" role="progressbar" style="width: 85%;" aria-valuenow="85"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newFeedbacks}}</span>%
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="col pr-0">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a href="javascript:;"
                   class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark d-block">
                    New Orders
                </a>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4">Fresh Order Amount</span>
                <div class="text-left text-danger font-weight-bolder font-size-h2 mr-2">
                    <span counto [step]="30" [duration]="1"
                          [countFrom]="0" [countTo]="dashboardTopStats.newOrders"
                          (countoChange)="dashboardTopStats.newOrdersCounter = $event">
                                {{dashboardTopStats.newOrdersCounter.toFixed(0)}}
                    </span>
                </div>
                <div class="progress progress-xs bg-danger-o-60">
                    <div class="progress-bar bg-danger" role="progressbar" style="width: 45%;" aria-valuenow="45"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newOrdersChange}}</span>%
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="col">
        <div class="card card-custom bgi-no-repeat card-stretch new-subscription-statistics h-100">
            <div class="card-body">
                <a href="javascript:;"
                   class="text-left font-weight-bolder text-dark font-size-h6 text-hover-state-dark d-block">
                    New Users
                </a>
                <span class="text-muted font-weight-bold text-left float-left col-12 p-0 mb-4">Joined New User</span>
                <div class="text-left text-success font-weight-bolder font-size-h2 mr-2">
                 <span counto [step]="30" [duration]="1"
                       [countFrom]="0" [countTo]="dashboardTopStats.newUsers"
                       (countoChange)="dashboardTopStats.newUsersCounter = $event">
                            {{dashboardTopStats.newUsersCounter.toFixed(0)}}
                        </span>
                </div>
                <div class="progress progress-xs bg-success-o-60">
                    <div class="progress-bar bg-success" role="progressbar" style="width: 57%;" aria-valuenow="57"
                         aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="text-muted mr-2 font-size-sm font-weight-bold">
                        Change
                    </span>
                    <span class="text-muted font-size-sm font-weight-bold">
                        <span class="counterup">{{dashboardTopStats.newUsersChange}}</span>%
                    </span>
                </div>
            </div>
        </div>
    </div>

</div>
