export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Streamlinea'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static readonly MinimumUpgradePaymentAmount = 1;

    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    static readonly WebAppGuiVersion = '3.0.0';

    static readonly erpType = {
        r365: '1',
        sage: '2'
    };

    static readonly client = {
        spintest: 'spintest',
        spinstage: 'spinstage',
        spin: 'spin',
        vallstest: 'vallstest',
        vallsstage: 'vallsstage',
        valls: 'valls'
    };

    static readonly dynamicPageNameId = {
        paymentDashboard: "00000000-0000-0000-0000-000000000001",
        apAging: "00000000-0000-0000-0000-000000000002",
        selectPayments: "00000000-0000-0000-0000-000000000003",
        approvePayments: "00000000-0000-0000-0000-000000000004",
        viewHistory: "00000000-0000-0000-0000-000000000005",
        exportPayments: "00000000-0000-0000-0000-000000000006",
        syncPayments: "00000000-0000-0000-0000-000000000007",
        oneTimePayments: "00000000-0000-0000-0000-000000000008",
        paymentTemplates: "00000000-0000-0000-0000-000000000009",
        paymentTemplatesCalendar: "00000000-0000-0000-0000-000000000010",
        scheduledPayments: "00000000-0000-0000-0000-000000000011",
    };

    static readonly componentId = {
        paymentDashboardBarChartApAgingAsOf: "00000000-0000-0000-0000-000000000001",
        paymentDashboardBarChartScheduledPaymentsPayThroughDate: "00000000-0000-0000-0000-000000000002",
        apAgingGridApAgingAsOf: "00000000-0000-0000-0000-000000000003",
        apAgingGridApAgingAgingCategory: "00000000-0000-0000-0000-000000000004",
        paymentTemplatesCalendarTemplates: "00000000-0000-0000-0000-000000000005",
        scheduledPaymentsGridTemplates: "00000000-0000-0000-0000-000000000006",
        scheduledPaymentsGridPayThroughDate: "00000000-0000-0000-0000-000000000007",
    };

    static readonly formControlTypes = {
        formInput: 0,
        formTextArea: 1,
        formNumeric: 2,
        formDate: 3,
        formCombobox: 4,
        formDropdown: 5,
        formCheckbox: 6,
        formRadioButton: 7,
        formFileUpload: 8,
        formDisplayImageLogo: 9,
        formInformationOnly: 10,
        formStaticText: 11,
        formSpacer: 12,
        formAttachments: 13,
        formArrayData: 14,
        formMaskedTextBox: 16,
    };

    static readonly gridControlTypes = {
        gridInput: 0,
        gridNumeric: 2,
        gridDate: 3,
        gridComboBox: 4,
        gridDropDown: 5,
        gridCheckBox: 6,
        gridInformationOnly: 10,
        gridDocument: 13,
        gridMaskedTextBox: 14,
        gridArrayData: 15,
    };
}
